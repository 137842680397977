<template>
    <b-button type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportDefault">
		<svg-icon class="icon is-small" icon="export"></svg-icon>
		<span>{{ $t('buttons.export') }}</span>
	</b-button>
</template>

<script>
import Api from '@/services/api'
import { successToast, errorToast } from '@/mixins/toast'
import Icon from '@/components/Icon'

export default {
    name: 'ExportDefault',
    components: {
		'svg-icon': Icon
	},
    props: {
        endpoint: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            exporting: false
        }
    },
    methods: {
        async exportDefault() {
            if (!this.endpoint) {
                errorToast(this.$t('alerts.export.endpoint'))
                return
            }

			try {
                this.exporting = true
				const response = await Api.post(`${this.endpoint}/export`)
				const { status } = response
				if (status === 422) {
					errorToast(this.$t('alerts.export.error'))
				} else {
					const { message } = response.data
					successToast(this.$t('alerts.export.success'))
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
        }
    }
}
</script>
